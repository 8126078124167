<template>
    <div>
        <el-dialog :visible.sync="visible">
            <span slot="title">新增/修改 记录</span>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label><b>姓名</b></label>
                        <el-input type="text" v-model="model.Visitor_Name" />
                    </div>
                    <!-- <div class="form-group">
                        <label><b>性别</b></label>
                        <el-select v-model="model.Visitor_Sex" placeholder="请选择" style="width: 100%;">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </div> -->
                    <div class="form-group">
                        <label><b>访问日期</b></label>
                        <el-date-picker v-model="model.Visite_Date" type="datetime" placeholder="选择日期" style="width: 100%;"
                            value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <!-- <div class="form-group">
                        <label><b>身份证号</b></label>
                        <el-input type="text" v-model="model.Visitor_Id_Numer" />
                    </div> -->
                    <div class="form-group">
                        <label><b>联系号码</b></label>
                        <el-input type="text" v-model="model.Visitor_Mobile" />
                    </div>
                    <!-- <div class="form-group">
                        <label><b>工作单位</b></label>
                        <el-input type="text" v-model="model.Visitor_Work_Place" />
                    </div> -->
                    <div class="form-group">
                        <label><b>来访人</b></label>
                        <el-input type="text" v-model="model.Be_Visited_Name" />
                    </div>
                    <!-- <div class="form-group">
                        <label><b>来访原因</b></label>
                        <el-select v-model="Reason" placeholder="请选择" style="width: 100%;">
                            <el-option label="会议" value="会议"></el-option>
                            <el-option label="访问" value="访问"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                        <el-input v-if="Reason == '其他'" type="text" v-model="model.Reason" style="margin-top: 5px;" />
                    </div> -->

                    <div class="form-group">
                        <label><b>确认人</b></label>
                        <el-select v-model="model.Sure_Name" placeholder="请选择类别" clearable style="display: block;"
                            @change="changeType">
                            <el-option v-for="it in grid.ls" :key="it.ID" :label="it.NAME" :value="it.NAME" />
                        </el-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label><b>性别</b></label>
                        <el-select v-model="model.Visitor_Sex" placeholder="请选择" style="width: 100%;">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </div>
                    <div class="form-group">
                        <label><b>身份证号</b></label>
                        <el-input type="text" v-model="model.Visitor_Id_Numer" />
                    </div>
                    <div class="form-group">
                        <label><b>工作单位</b></label>
                        <el-input type="text" v-model="model.Visitor_Work_Place" />
                    </div>
                    <div class="form-group">
                        <label><b>来访原因</b></label>
                        <el-select v-model="Reason" placeholder="请选择" style="width: 100%;">
                            <el-option label="会议" value="会议"></el-option>
                            <el-option label="访问" value="访问"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                        <el-input v-if="Reason == '其他'" type="text" v-model="model.Reason" style="margin-top: 5px;" />
                    </div>
                </div>
            </div>
            <div slot="footer" v-loading="loading">
                <el-button icon="el-icon-close" @click="visible = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {},
            visible: false,
            loading: false,
            Reason: '',
            grid: { ls: [] }
        }
    },
    mounted() {

    },
    methods: {
        init(mould) {
            console.log(mould)
            if (mould != undefined) {
                this.model = mould
                if (mould.Reason != '会议' && mould.Reason != '访问') {
                    this.Reason = '其他'
                } else {
                    this.Reason = mould.Reason
                }
            } else {
                this.model = {}
                this.Reason = ''
            }
            this.visible = true
            this.getList()
        },
        get() {
            // School/VIS/VisRegister/Get
        },
        getList() {
            let self = this;
            // this.grid.loading = true;
            this.whale.remote.getCollection({
                url: "/api/School/SAFE/SafeMenderApi/GetList",
                // data: this.grid.sea,
                completed: function (its) {
                    self.grid.ls = its;
                    // self.grid.total = n;
                    // self.grid.loading = false;
                }
            })
        },
        changeType(e) {
            console.log(e)
            let self = this
            this.grid.ls.map(i => {
                if (i.NAME == e) {
                    self.model.Sure_Id = i.ID
                }
            })
        },
        submit() {
            if (this.model.Visitor_Name == '') {
                this.$message.warning('请填写姓名')
                return
            }
            if (this.model.Visitor_Sex == '') {
                this.$message.warning('请选择性别')
                return
            }
            if (this.model.Visite_Date == '') {
                this.$message.warning('请选择访问日期')
                return
            }
            if (this.model.Visitor_Id_Numer == '') {
                this.$message.warning('请填写身份证号')
                return
            }
            if (this.model.Visitor_Mobile == '') {
                this.$message.warning('请填写联系号码')
                return
            }
            if (this.model.Visitor_Work_Place == '') {
                this.$message.warning('请填写工作单位')
                return
            }
            if (this.Reason == '') {
                this.$message.warning('请选择访问原因')
                return
            }
            if (this.Reason == '其他' && this.model.Reason == '') {
                this.$message.warning('请选择访问原因')
                return
            }
            if (this.Reason != '其他') {
                this.model.Reason = this.Reason
            }

            if (this.model.ID) {
                this.edit()
            } else {
                this.create()
            }
        },
        create() {
            let self = this
            this.whale.remote.getResult({
                url: '/api/School/VIS/VisRegister/CreateNew',
                data: self.model,
                completed() {
                    self.visible = false
                    self.$message.success('提交成功')
                    self.$emit("on-saved");
                }
            })
        },
        edit() {
            let self = this
            this.whale.remote.getResult({
                url: '/api/School/VIS/VisRegister/Update',
                data: self.model,
                completed() {
                    self.visible = false
                    self.$message.success('修改成功')
                    self.$emit("on-saved");
                }
            })
        }
    },
}
</script>
<style lang="less" scoped></style>