<template>
    <div>
        <el-dialog :visible.sync="visible">
            <div class="row">
                <div class="col-md-12" style="text-align: center;">
                    <div class="form-group">
                        <img :src="'data:image/png;base64,' + base64" alt="">
                    </div>
                    <el-button @click="visible = false">关闭</el-button>
                    <el-button type="success" @click="downERCode(base64)">下载</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            base64: '',
            visible: false
        }
    },
    methods: {
        QRCode() {
            this.visible = true
            let self = this
            this.whale.remote.getResult({
                url: '/api/School/VIS/VisRegister/ShowQrCode',
                completed(its) {
                    console.log(its)
                    self.base64 = its.DATA
                }
            })
        },
        downERCode(item) {
            this.downLoadBase64('二维码.png', item)
        },
        downLoadBase64(filename, content) {
            let aLink = document.createElement('a');
            let blob = this.base64ToBlob(content); //new Blob([content]);

            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = filename;
            aLink.href = URL.createObjectURL(blob);
            aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
        }
        ,
        base64ToBlob(code) {
            let contentType = code.split(':')[1];
            let raw = window.atob(code);
            let rawLength = raw.length;
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: contentType });
        }
    }
}
</script>